import React from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Box,
  Grow
} from '@mui/material';
import axios from 'axios';

// Componente que genera partículas de símbolos de programación
const FallingParticles = () => {
  const codeSymbols = ['{}', '[]', '<>', '()', '=>', ';', '||', '&&', 'if', 'for'];
  const particles = React.useMemo(() => {
    const arr = [];
    for (let i = 0; i < 30; i++) {
      const left = Math.random() * 100; // en porcentaje
      const size = Math.random() * 20 + 14; // tamaño de fuente entre 14 y 34px
      const delay = Math.random() * -20; // retraso negativo para dispersión
      const symbol = codeSymbols[Math.floor(Math.random() * codeSymbols.length)];
      arr.push({ left, size, delay, symbol });
    }
    return arr;
  }, []);

  return (
    <>
      {particles.map((p, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: '-10%',
            left: `${p.left}%`,
            fontSize: p.size,
            color: '#ff073a', // acento en rojo vibrante
            opacity: 0.7,
            animation: `fall 25s linear infinite`,
            animationDelay: `${p.delay}s`
          }}
        >
          {p.symbol}
        </Box>
      ))}
      <style>{`
        @keyframes fall {
          0% {
            transform: translateY(0px);
            opacity: 0.7;
          }
          100% {
            transform: translateY(110vh);
            opacity: 0.1;
          }
        }
      `}</style>
    </>
  );
};

const Login = ({ setAutenticado }) => {
  const [clave, setClave] = React.useState('');
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    setChecked(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API_URL = window.location.origin.replace(':3000', ':3001');
      const response = await axios.post(`${API_URL}/api/login`, { clave });

      localStorage.setItem('token', response.data.token);
      setAutenticado(true);
    } catch (error) {
      alert('Clave incorrecta o expirada');
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        overflow: 'hidden',
        background: 'linear-gradient(135deg, #2D033B 0%, #5E239D 100%)'
      }}
    >
      {/* Partículas de símbolos en movimiento en el fondo */}
      <FallingParticles />
      <Container maxWidth="xs" sx={{ position: 'relative', zIndex: 1 }}>
        <Grow in={checked} style={{ transformOrigin: 'center top' }} timeout={1000}>
          <Paper
            elevation={8}
            sx={{
              padding: 4,
              borderRadius: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              mt: 8
            }}
          >
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#fff',
                textShadow: '1px 1px 2px #000'
              }}
            >
              FuRixX
            </Typography>
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{
                fontWeight: 'bold',
                color: '#ff073a',
                textShadow: '1px 1px 2px #000'
              }}
            >
              Lamborghini
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Clave Temporal"
                variant="outlined"
                fullWidth
                margin="normal"
                value={clave}
                onChange={(e) => setClave(e.target.value)}
                sx={{
                  backgroundColor: '#fff',
                  borderRadius: 1
                }}
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  mt: 2,
                  paddingY: 1.5,
                  fontSize: '1rem',
                  backgroundColor: '#ff073a',
                  '&:hover': { backgroundColor: '#e60033' }
                }}
              >
                Ingresar
              </Button>
            </form>
          </Paper>
        </Grow>
      </Container>
    </Box>
  );
};

export default Login;
