import React, { useState, useEffect, useMemo } from 'react';
import { Container, Typography, Paper, Box, TextField, Button, Grow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

/* ---------------------------
   Fondo: FallingParticles sin interacción con el mouse
----------------------------- */
const FallingParticles = () => {
  const codeSymbols = ["{}", "[]", "<>", "()", "=>", ";", "||", "&&", "if", "for"];
  // Se genera una única vez al montar.
  const particles = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 30; i++) {
      const left = Math.random() * 100;
      const size = Math.random() * 20 + 14;
      const delay = Math.random() * -20;
      const symbol = codeSymbols[Math.floor(Math.random() * codeSymbols.length)];
      arr.push({ left, size, delay, symbol });
    }
    return arr;
  }, []);
  
  return (
    <>
      {particles.map((p, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: '-10%',
            left: `${p.left}%`,
            fontSize: p.size,
            color: '#ff073a',
            opacity: 0.7,
            animation: 'fall 25s linear infinite',
            animationDelay: `${p.delay}s`,
            pointerEvents: 'none'
          }}
        >
          {p.symbol}
        </Box>
      ))}
      <style>{`
        @keyframes fall {
          0% { transform: translateY(0px); opacity: 0.7; }
          100% { transform: translateY(110vh); opacity: 0.1; }
        }
      `}</style>
    </>
  );
};

/* ---------------------------
   Botón de Discord: usa Font Awesome: "fab fa-discord" (icono minimalista en blanco).
----------------------------- */
const DiscordButton = () => (
    <a
      href="https://discord.gg/xxVcdH88su"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: '#fff', textDecoration: 'none' }}
    >
      <FontAwesomeIcon icon={faDiscord} style={{ fontSize: '25px' }} />
    </a>
  );

const ClientLogin = ({ setClientAuth }) => {
  const [key, setKey] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const [tilt, setTilt] = useState({ rotateX: 0, rotateY: 0 });
  const navigate = useNavigate();

  // Aplica el efecto tilt únicamente al contenedor del recuadro de login.
  const handleMouseMove = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;
    const deltaX = (event.clientX - centerX) / (rect.width / 2);
    const deltaY = (event.clientY - centerY) / (rect.height / 2);
    setTilt({
      rotateY: deltaX * 5,
      rotateX: -deltaY * 5
    });
  };

  useEffect(() => {
    if (localStorage.getItem('clientToken')) {
      navigate('/cliente/panel', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    setChecked(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const API_URL = window.location.origin.replace(':3000', ':3001');
      const response = await axios.post(`${API_URL}/api/client/login`, { key, password });
      localStorage.setItem('clientToken', response.data.token);
      localStorage.setItem('clientLicense', JSON.stringify(response.data.license));
      setClientAuth(true);
      navigate('/cliente/panel', { state: response.data.license, replace: true });
    } catch (err) {
      alert('Key o contraseña incorrecta');
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #2D033B 0%, #5E239D 100%)',
        overflow: 'hidden',
        padding: 2,
      }}
    >
      {/* Fondo: Particles (generadas una única vez, sin interacción con el mouse) */}
      <FallingParticles />

      <Grow in={checked} timeout={1000}>
        <Container maxWidth="xs" sx={{ position: 'relative', zIndex: 1, pt: 8 }}>
          {/* Contenedor del recuadro de login con efecto tilt */}
          <Box onMouseMove={handleMouseMove} sx={{ position: 'relative' }}>
            <Paper
              onMouseLeave={() => setTilt({ rotateX: 0, rotateY: 0 })}
              elevation={8}
              sx={{
                padding: 4,
                borderRadius: 2,
                backgroundColor: 'rgba(255,255,255,0.95)',
                transform: `perspective(1000px) rotateX(${tilt.rotateX}deg) rotateY(${tilt.rotateY}deg)`,
                transition: 'transform 0.2s ease-out',
              }}
            >
              <Typography
                variant="h4"
                align="center"
                sx={{ fontWeight: 'bold', mb: 2, color: '#2D033B' }}
              >
                FuRixX Panel
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  label="License Key"
                  fullWidth
                  margin="normal"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                />
                <TextField
                  label="Contraseña"
                  type="password"
                  fullWidth
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    paddingY: 1.5,
                    backgroundColor: '#ff073a',
                    '&:hover': { backgroundColor: '#e60033' },
                  }}
                >
                  Ingresar
                </Button>
              </form>
            </Paper>
          </Box>
        </Container>
      </Grow>

      {/* Botón de Discord fijo en la parte inferior central */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 2,
        }}
      >
        <DiscordButton />
      </Box>
    </Box>
  );
};

export default ClientLogin;
