import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  Box,
  Container,
  Typography,
  Slide
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

// Interceptor global para manejar errores 401 (token expirado)
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Componente de partículas con símbolos de programación
const FallingParticles = () => {
  const codeSymbols = ['{}', '[]', '<>', '()', '=>', ';', '||', '&&', 'if', 'for'];
  const particles = React.useMemo(() => {
    const arr = [];
    for (let i = 0; i < 30; i++) {
      const left = Math.random() * 100; // posición en porcentaje
      const size = Math.random() * 20 + 14; // entre 14 y 34px
      const delay = Math.random() * -20; // retraso negativo para dispersión
      const symbol = codeSymbols[Math.floor(Math.random() * codeSymbols.length)];
      arr.push({ left, size, delay, symbol });
    }
    return arr;
  }, []);

  return (
    <>
      {particles.map((p, index) => (
        <Box
          key={index}
          sx={{
            position: 'absolute',
            top: '-10%',
            left: `${p.left}%`,
            fontSize: p.size,
            color: '#ff073a',
            opacity: 0.7,
            animation: `fall 25s linear infinite`,
            animationDelay: `${p.delay}s`
          }}
        >
          {p.symbol}
        </Box>
      ))}
      <style>{`
        @keyframes fall {
          0% {
            transform: translateY(0px);
            opacity: 0.7;
          }
          100% {
            transform: translateY(110vh);
            opacity: 0.1;
          }
        }
      `}</style>
    </>
  );
};

const PanelLicencias = () => {
  const [licencias, setLicencias] = useState([]);
  const [refreshCount, setRefreshCount] = useState(0);
  const [open, setOpen] = useState(false);
  const [newLicencia, setNewLicencia] = useState({ ip: '' });
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setChecked(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setRefreshCount((prev) => prev + 1);
    }, 30000); // Actualizar cada 30 segundos
    return () => clearInterval(interval);
  }, []);

  // Función para generar la licencia con el formato "XX-XXXX-XXXX-XXXX"
  const generarLicencia = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const getRandom = (length) =>
      Array.from({ length }, () => chars[Math.floor(Math.random() * chars.length)]).join('');
    return `${getRandom(2)}-${getRandom(4)}-${getRandom(4)}-${getRandom(4)}`;
  };

  // Función para cerrar sesión
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/login';
  };

  // Nueva columna para administrar (redirige al detalle de la licencia)
  const colAdmin = {
    field: 'administrar',
    headerName: 'Administrar',
    width: 120,
    renderCell: (params) => (
      <Tooltip title="Administrar">
        <IconButton
        onClick={() =>
            navigate(`/detalles-licencia/${params.row.id}`, { state: params.row })
        }
        >
        <SettingsIcon />
        </IconButton>
      </Tooltip>
    )
  };

  const columns = [
    {
      field: 'licencia',
      headerName: 'Licencia',
      width: 250,
      editable: false,
      renderCell: (params) => {
        const lastHeartbeat = new Date(params.row.ultimo_latido);
        const now = new Date();
        const diffInMinutes = (now - lastHeartbeat) / 1000 / 20;
        const color = diffInMinutes < 2 ? 'green' : 'red';
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color, marginRight: 8 }}>●</span>
            {params.value}
          </Box>
        );
      }
    },
    {
      field: 'ip',
      headerName: 'IP',
      width: 200,
      editable: true,
      preProcessEditCellProps: (params) => {
        // Validación para IPv4 e IPv6
        const ipv4Pattern = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;
        const ipv6Pattern =
          /^(([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}|(([0-9a-fA-F]{1,4}:){1,7}|:):|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}(([0-9]{1,3}\.){3}[0-9]{1,3}))$/;
        const isValid = ipv4Pattern.test(params.props.value) || ipv6Pattern.test(params.props.value);
        return { ...params.props, error: !isValid };
      }
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 150,
      type: 'singleSelect',
      valueOptions: ['activa', 'inactiva'],
      editable: true,
      cellClassName: (params) =>
        params.value === 'activa' ? 'estado-activo' : 'estado-inactiva'
    },
    {
      field: 'acciones',
      headerName: 'Acciones',
      width: 120,
      renderCell: (params) => (
        <Button color="error" onClick={() => handleDeleteLicencia(params.row.id)}>
          Eliminar
        </Button>
      )
    },
    colAdmin
  ];

  // Construcción de la URL de la API (reemplazando el puerto 3000 por 3001)
  const getApiUrl = () => {
    const apiUrl = window.location.origin;
    return apiUrl.replace(':3000', ':3001');
  };

  const cargarLicencias = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${getApiUrl()}/api/licencias`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { t: Date.now() } // Evitar caché
      });
      const licenciasConId = response.data.map((lic) => ({
        ...lic,
        id: lic.id
      }));
      setLicencias(licenciasConId);
    } catch (error) {
      console.error('Error cargando licencias:', error);
    }
  };

  const handleAddLicencia = async () => {
    if (!newLicencia.ip.trim()) {
      alert('La IP es obligatoria');
      return;
    }
    const licenciaGenerada = generarLicencia();
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${getApiUrl()}/api/licencias`,
        { licencia: licenciaGenerada, ip: newLicencia.ip, estado: 'activa' },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setOpen(false);
      setNewLicencia({ ip: '' });
      setRefreshCount((prev) => prev + 1);
    } catch (error) {
      console.error('Error agregando licencia:', error);
    }
  };

  const handleDeleteLicencia = async (id) => {
    if (!window.confirm(`¿Borrar licencia con ID ${id}?`)) return;
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${getApiUrl()}/api/licencias/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setRefreshCount((prev) => prev + 1);
    } catch (error) {
      console.error('Error eliminando licencia:', error);
    }
  };

  // Uso de la nueva API de edición en el DataGrid para actualizar filas
  const handleProcessRowUpdate = async (newRow, oldRow) => {
    const token = localStorage.getItem('token');
    const updates = {};
    if (newRow.ip !== oldRow.ip) updates.ip = newRow.ip;
    if (newRow.estado !== oldRow.estado) updates.estado = newRow.estado;
    if (Object.keys(updates).length === 0) return oldRow;
    try {
      const response = await axios.put(
        `${getApiUrl()}/api/licencias/${newRow.id}`,
        updates,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        setRefreshCount((prev) => prev + 1);
        return newRow;
      } else {
        throw new Error('Error actualizando la licencia');
      }
    } catch (error) {
      console.error('Error actualizando licencia:', error);
      throw error;
    }
  };

  useEffect(() => {
    cargarLicencias();
  }, [refreshCount]);

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #2D033B 0%, #5E239D 100%)',
        padding: 2,
        overflow: 'hidden'
      }}
    >
      {/* Partículas de símbolos en fondo */}
      <FallingParticles />
      <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 1, pt: 4 }}>
        <Slide direction="down" in={checked} timeout={800}>
          <Paper
            elevation={8}
            sx={{
              padding: 3,
              borderRadius: 2,
              backgroundColor: 'rgba(255,255,255,0.95)'
            }}
          >
            {/* Header: título y botón de logout */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2D033B' }}>
                FuRixX Panel
              </Typography>
              <Tooltip title="Cerrar sesión">
                <IconButton onClick={handleLogout}>
                  <ExitToAppIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {/* Controles: refrescar y agregar */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Tooltip title="Actualizar licencias">
                <IconButton onClick={() => setRefreshCount((prev) => prev + 1)}>
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Agregar Licencia">
                <IconButton onClick={() => setOpen(true)} sx={{ ml: 1 }}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {/* DataGrid */}
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={licencias}
                columns={columns}
                getRowId={(row) => row.id}
                editMode="row"
                processRowUpdate={handleProcessRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                onProcessRowUpdateError={(error) => console.error(error)}
              />
            </div>
          </Paper>
        </Slide>
      </Container>
      {/* Dialog para agregar licencia */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Agregar Licencia</DialogTitle>
        <DialogContent>
          <TextField
            label="IP"
            fullWidth
            variant="outlined"
            margin="dense"
            value={newLicencia.ip}
            onChange={(e) => setNewLicencia({ ip: e.target.value })}
            sx={{ backgroundColor: '#fff', borderRadius: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleAddLicencia} color="primary">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PanelLicencias;
