import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// Componentes de administrador
import Login from './Login';
import PanelLicencias from './PanelLicencias';
import DetallesLicencia from './DetallesLicencia';

// Componentes de cliente
import ClientLogin from './ClientLogin';
import ClientPanel from './ClientPanel';

const App = () => {
  // Estados de autenticación para administrador y cliente
  const [adminAuth, setAdminAuth] = useState(() => !!localStorage.getItem('token'));
  const [clientAuth, setClientAuth] = useState(() => !!localStorage.getItem('clientToken'));

  // Sincroniza los estados de autenticación con localStorage
  useEffect(() => {
    const handleStorageChange = () => {
      setAdminAuth(!!localStorage.getItem('token'));
      setClientAuth(!!localStorage.getItem('clientToken'));
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {/* Rutas de administrador */}
        <Route 
          path="/login" 
          element={
            !adminAuth ? 
              <Login setAutenticado={setAdminAuth} /> : 
              <Navigate to="/" replace />
          } 
        />
        <Route 
          path="/" 
          element={
            adminAuth ? 
              <PanelLicencias /> : 
              <Navigate to="cliente/login" replace />
          } 
        />
        <Route 
          path="/detalles-licencia/:id" 
          element={
            adminAuth ? 
              <DetallesLicencia /> : 
              <Navigate to="/login" replace />
          } 
        />

        {/* Rutas de cliente */}
        <Route 
          path="/cliente/login" 
          element={
            !clientAuth ? 
              <ClientLogin setClientAuth={setClientAuth} /> : 
              <Navigate to="/cliente/panel" replace />
          } 
        />
        <Route 
          path="/cliente/panel" 
          element={
            clientAuth ? 
              <ClientPanel setClientAuth={setClientAuth} /> : 
              <Navigate to="/cliente/login" replace />
          } 
        />


        {/* Ruta por defecto */}
        <Route 
          path="*" 
          element={
            <Navigate 
              to={adminAuth ? "/" : clientAuth ? "/cliente/panel" : "/login"} 
              replace 
            />
          } 
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
