import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Typography,
  Paper,
  Box,
  Button,
  Slide,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";

// Para el editor con resaltado (usando react-ace)
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript"; // modo fallback para SQF
import "ace-builds/src-noconflict/theme-monokai";

// ==========================================================================
// Función auxiliar para obtener la URL API: se reemplaza :3000 por :3001
const getApiUrl = () => {
  const apiUrl = window.location.origin;
  return apiUrl.replace(':3000', ':3001');
};
// Definimos también la URL para los comandos del DLL:
const DLL_COMMAND_URL = getApiUrl() + "/api/dll/command";
// ==========================================================================

// Componente de partículas con símbolos (sin interacción)
const FallingParticles = () => {
  const codeSymbols = ["{}", "[]", "<>", "()", "=>", ";", "||", "&&", "if", "for"];
  const particles = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 30; i++) {
      const left = Math.random() * 100;
      const size = Math.random() * 20 + 14;
      const delay = Math.random() * -20;
      const symbol = codeSymbols[Math.floor(Math.random() * codeSymbols.length)];
      arr.push({ left, size, delay, symbol });
    }
    return arr;
  }, []);
  return (
    <>
      {particles.map((p, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: "-10%",
            left: `${p.left}%`,
            fontSize: p.size,
            color: "#ff073a",
            opacity: 0.7,
            animation: "fall 25s linear infinite",
            animationDelay: `${p.delay}s`,
            pointerEvents: "none"
          }}
        >
          {p.symbol}
        </Box>
      ))}
      <style>{`
        @keyframes fall {
          0% { transform: translateY(0px); opacity: 0.7; }
          100% { transform: translateY(110vh); opacity: 0.1; }
        }
      `}</style>
    </>
  );
};

const formatDate = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleString("es-ES", {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
};

/* ---------------------------
   ModernInputDialog: Diálogo genérico para pedir un input (mensaje) 
   con confirmación.
----------------------------- */
const ModernInputDialog = ({
  open,
  title,
  label,
  value,
  onValueChange,
  onConfirm,
  onClose,
  multiline = false
}) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <TextField
        label={label}
        fullWidth
        multiline={multiline}
        minRows={multiline ? 4 : 1}
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cancelar
      </Button>
      <Button
        onClick={() => {
          if (window.confirm("¿Estás seguro?")) {
            onConfirm(value);
          }
        }}
        variant="contained"
        color="primary"
      >
        Confirmar
      </Button>
    </DialogActions>
  </Dialog>
);

/* ---------------------------
   SQFExecutionDialog: Diálogo para ejecutar código SQF con resaltado.
----------------------------- */
const SQFExecutionDialog = ({ open, code, onCodeChange, onConfirm, onClose }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    <DialogTitle>Ejecución IC</DialogTitle>
    <DialogContent dividers>
      <AceEditor
        mode="javascript" 
        theme="monokai"
        name="sqf_execution_editor"
        fontSize={14}
        width="100%"
        height="300px"
        value={code}
        onChange={onCodeChange}
        setOptions={{
          useWorker: false,
        }}
        editorProps={{ $blockScrolling: true }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="secondary">
        Cancelar
      </Button>
      <Button
        onClick={() => {
          if (window.confirm("¿Estás seguro de ejecutar este código?")) {
            onConfirm(code);
          }
        }}
        variant="contained"
        color="primary"
      >
        Ejecutar
      </Button>
    </DialogActions>
  </Dialog>
);

const DetallesLicencia = () => {
  const { state: licencia } = useLocation();
  const navigate = useNavigate();

  // Convertir la licencia de Location a estado para actualización dinámica.
  const [licenciaData, setLicenciaData] = useState(licencia);
  const [showPassword, setShowPassword] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [checked, setChecked] = useState(false);

  // Estados para diálogos de inputs.
  const [openInputDialog, setOpenInputDialog] = useState(false);
  const [inputDialogTitle, setInputDialogTitle] = useState("");
  const [inputDialogLabel, setInputDialogLabel] = useState("");
  const [inputDialogValue, setInputDialogValue] = useState("");
  const [inputDialogMultiline, setInputDialogMultiline] = useState(false);
  const [onInputConfirm, setOnInputConfirm] = useState(() => (val) => {});

  // Estado para la ventana de ejecución IC (SQF).
  const [openSQFDialog, setOpenSQFDialog] = useState(false);
  const [sqfCode, setSqfCode] = useState("");

  useEffect(() => {
    setChecked(true);
  }, []);

  // Función para refrescar los datos de la licencia.
  const fetchLicenseData = async () => {
    try {
      const API_URL = getApiUrl();
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_URL}/api/licencias/${licenciaData.id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.data) {
        setLicenciaData(response.data);
      }
    } catch (error) {
      console.error("Error al obtener datos actualizados de la licencia:", error);
    }
  };

  // Función para resetear la última modificación.
  const handleResetTimer = async () => {
    try {
      setResetLoading(true);
      const API_URL = getApiUrl();
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/api/licencias/${licenciaData.id}/reset`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        alert("El tiempo de espera se ha reseteado.");
        await fetchLicenseData();
      } else {
        alert("No se pudo resetear el tiempo de espera.");
      }
    } catch (error) {
      console.error("Error al resetear:", error);
      alert("Error al resetear el tiempo.");
    } finally {
      setResetLoading(false);
    }
  };

  // Función para actualizar la contraseña.
  const handlePasswordUpdate = async () => {
    if (!newPassword) {
      alert("Por favor, ingresa la nueva contraseña.");
      return;
    }
    try {
      const API_URL = getApiUrl();
      const token = localStorage.getItem("token");
      const response = await axios.put(
        `${API_URL}/api/licencias/${licenciaData.id}/password`,
        { password: newPassword },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        alert("Contraseña actualizada exitosamente.");
        setLicenciaData({ ...licenciaData, password: newPassword });
        setOpenPasswordDialog(false);
        setNewPassword("");
      } else {
        alert("No se pudo actualizar la contraseña.");
      }
    } catch (error) {
      console.error("Error actualizando la contraseña:", error);
      alert("Error actualizando la contraseña.");
    }
  };

  // --- Funciones para los diálogos modernos ---

  const openDialogForInput = (title, label, multiline, onConfirmCallback) => {
    setInputDialogTitle(title);
    setInputDialogLabel(label);
    setInputDialogValue("");
    setInputDialogMultiline(multiline);
    setOnInputConfirm(() => onConfirmCallback);
    setOpenInputDialog(true);
  };

  const handleIngameMessage = () => {
    openDialogForInput("Mensaje in Game", "Ingresa el mensaje in game:", false, async (val) => {
      try {
        await axios.post(DLL_COMMAND_URL, { 
          command: "MENSAJEINGAME", 
          data: val, 
          licencia: licenciaData.licencia 
        }, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
        alert("Orden enviada: Mensaje in Game");
        setOpenInputDialog(false); // Cerrar el diálogo después de confirmar
      } catch (error) {
        alert("Error al enviar la orden: " + error.message);
      }
    });
  };

  const handleVPSMessage = () => {
    openDialogForInput("Mensaje para VPS", "Ingresa el mensaje para la VPS:", false, async (val) => {
      try {
        await axios.post(DLL_COMMAND_URL, { 
          command: "MENSAJEVPS", 
          data: val, 
          licencia: licenciaData.licencia 
        }, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
        alert("Orden enviada: Mensaje para VPS");
        setOpenInputDialog(false); // Cerrar el diálogo después de confirmar
      } catch (error) {
        alert("Error al enviar la orden: " + error.message);
      }
    });
  };

  const handleSQFExecution = () => {
    setSqfCode("");
    setOpenSQFDialog(true);
  };

  const confirmSQFExecution = async (code) => {
    try {
      await axios.post(DLL_COMMAND_URL, { 
        command: "EJECUTARIC", 
        data: code, 
        licencia: licenciaData.licencia 
      }, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
      alert("Orden enviada: Ejecución IC");
      setOpenSQFDialog(false); // Cerrar el diálogo después de confirmar
    } catch (error) {
      alert("Error al enviar la orden: " + error.message);
    }
  };

  const handleStopServer = async () => {
    if (!window.confirm("¿Estás seguro de que deseas detener el servidor?")) return;
    try {
      await axios.post(DLL_COMMAND_URL, { 
        command: "PARAR", 
        data: "PARATE", 
        licencia: licenciaData.licencia 
      }, { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } });
      alert("Orden enviada: Parar Servidor");
    } catch (error) {
      alert("Error al enviar la orden: " + error.message);
    }
  };

  if (!licenciaData) {
    return (
      <Box
        sx={{
          position: "relative",
          minHeight: "100vh",
          background: "linear-gradient(135deg, #2D033B 0%, #5E239D 100%)",
          padding: 2,
          overflow: "hidden"
        }}
      >
        <FallingParticles />
        <Container maxWidth="sm" sx={{ position: "relative", zIndex: 1, pt: 4 }}>
          <Paper
            elevation={8}
            sx={{
              padding: 4,
              borderRadius: 2,
              backgroundColor: "rgba(255,255,255,0.95)",
              textAlign: "center"
            }}
          >
            <Typography variant="h6" color="error" sx={{ mb: 2 }}>
              No se encontró la información de la licencia.
            </Typography>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Volver
            </Button>
          </Paper>
        </Container>
      </Box>
    );
  }

  return (
    <Slide direction="up" in={checked} timeout={800}>
      <Box
        sx={{
          position: "relative",
          minHeight: "100vh",
          background: "linear-gradient(135deg, #2D033B 0%, #5E239D 100%)",
          padding: 2,
          overflow: "hidden"
        }}
      >
        <FallingParticles />
        <Container maxWidth="md" sx={{ position: "relative", zIndex: 1, pt: 4 }}>
          <Paper
            elevation={10}
            sx={{
              padding: 4,
              borderRadius: 3,
              backgroundColor: "rgba(255,255,255,0.95)",
              transition: "transform 0.3s ease",
              "&:hover": { transform: "scale(1.02)" }
            }}
          >
            {/* Cabecera con título, botón para volver y actualizar datos */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3
              }}
            >
              <Typography variant="h4" sx={{ fontWeight: "bold", color: "#2D033B" }}>
                Administrar Licencia
              </Typography>
              <Box>
                <IconButton onClick={fetchLicenseData} title="Actualizar datos">
                  <Typography variant="body2">Actualizar</Typography>
                </IconButton>
                <Button
                  variant="outlined"
                  onClick={() => navigate(-1)}
                  sx={{ fontWeight: "bold", ml: 1 }}
                >
                  Volver
                </Button>
              </Box>
            </Box>

            <Box sx={{ mb: 4, textAlign: "left" }}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                <strong>Licencia:</strong> {licenciaData.licencia}
              </Typography>
              <Typography variant="h6" sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <strong>Contraseña:</strong>{" "}
                <span style={{ marginLeft: "8px", fontFamily: "monospace" }}>
                  {showPassword ? licenciaData.password : "••••••••"}
                </span>
                <IconButton onClick={() => setShowPassword(!showPassword)} size="small">
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
                <IconButton onClick={() => setOpenPasswordDialog(true)} size="small" sx={{ ml: 1 }}>
                  <EditIcon />
                </IconButton>
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
                <strong>IP:</strong> {licenciaData.ip}
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
                <strong>Estado:</strong> {licenciaData.estado}
              </Typography>
              <Typography variant="h6" sx={{ mb: 1 }}>
                <strong>Último Latido:</strong> {formatDate(licenciaData.ultimo_latido)}
              </Typography>
              <Typography variant="h6" sx={{ mb: 1, display: "flex", alignItems: "center" }}>
                <strong>Última Modificación:</strong>{" "}
                {licenciaData.ultima_ip_modificacion
                  ? formatDate(licenciaData.ultima_ip_modificacion)
                  : "No registrada"}
                <IconButton onClick={handleResetTimer} color="primary" sx={{ ml: 1 }} disabled={resetLoading}>
                  <RestartAltIcon />
                </IconButton>
              </Typography>
            </Box>

            {/* Botones de interacción */}
            <Box sx={{ display: "flex", gap: 2, justifyContent: "center", mt: 4, flexWrap: 'wrap' }}>
              <Button variant="contained" color="primary" onClick={handleIngameMessage}>
                Mensaje in Game
              </Button>
              <Button variant="contained" color="secondary" onClick={handleVPSMessage}>
                Advertencia GLOBAL
              </Button>
              <Button variant="contained" color="error" onClick={handleStopServer}>
                Parar Servidor
              </Button>
              <Button variant="contained" color="info" onClick={handleSQFExecution}>
                Ejecución IC
              </Button>
            </Box>
          </Paper>
        </Container>

        {/* Diálogo para cambiar contraseña */}
        <Dialog open={openPasswordDialog} onClose={() => setOpenPasswordDialog(false)}>
          <DialogTitle>Actualizar Contraseña</DialogTitle>
          <DialogContent>
            <TextField
              label="Nueva Contraseña"
              fullWidth
              margin="dense"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPasswordDialog(false)} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handlePasswordUpdate} variant="contained" color="primary">
              Actualizar
            </Button>
          </DialogActions>
        </Dialog>

        {/* Diálogo moderno para inputs (Mensaje in Game y VPS) */}
        <ModernInputDialog
          open={openInputDialog}
          title={inputDialogTitle}
          label={inputDialogLabel}
          value={inputDialogValue}
          onValueChange={setInputDialogValue}
          multiline={inputDialogMultiline}
          onConfirm={(val) => onInputConfirm(val)}
          onClose={() => setOpenInputDialog(false)}
        />

        {/* Diálogo para Ejecución IC */}
        <SQFExecutionDialog
          open={openSQFDialog}
          code={sqfCode}
          onCodeChange={setSqfCode}
          onConfirm={confirmSQFExecution}
          onClose={() => setOpenSQFDialog(false)}
        />
      </Box>
    </Slide>
  );
};

export default DetallesLicencia;
