import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Typography,
  Paper,
  Box,
  TextField,
  Button,
  Grow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import RefreshIcon from "@mui/icons-material/Refresh";

// Componente de partículas para el fondo (estilo programador)
const FallingParticles = () => {
  const codeSymbols = ["{}", "[]", "<>", "()", "=>", ";", "||", "&&", "if", "for"];
  const particles = useMemo(() => {
    const arr = [];
    for (let i = 0; i < 30; i++) {
      const left = Math.random() * 100; // posición en %
      const size = Math.random() * 20 + 14; // entre 14 y 34px
      const delay = Math.random() * -20; // retraso negativo
      const symbol =
        codeSymbols[Math.floor(Math.random() * codeSymbols.length)];
      arr.push({ left, size, delay, symbol });
    }
    return arr;
  }, []);
  return (
    <>
      {particles.map((p, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: "-10%",
            left: `${p.left}%`,
            fontSize: p.size,
            color: "#ff073a",
            opacity: 0.7,
            animation: "fall 25s linear infinite",
            animationDelay: `${p.delay}s`
          }}
        >
          {p.symbol}
        </Box>
      ))}
      <style>{`
        @keyframes fall {
          0% { transform: translateY(0px); opacity: 0.7; }
          100% { transform: translateY(110vh); opacity: 0.1; }
        }
      `}</style>
    </>
  );
};

const ClientPanel = ({ setClientAuth = () => {} }) => {
  const navigate = useNavigate();

  // Recuperamos la licencia almacenada en localStorage
  const computedLicense = useMemo(() => {
    const stored = localStorage.getItem("clientLicense");
    return stored ? JSON.parse(stored) : null;
  }, []);
  // Recuperamos el token del cliente de localStorage
  const clientToken = localStorage.getItem("clientToken");

  const [license, setLicense] = useState(computedLicense);
  const [newIp, setNewIp] = useState(computedLicense ? computedLicense.ip : "");
  const [openDialog, setOpenDialog] = useState(false);
  const [allowUpdate, setAllowUpdate] = useState(true);
  const [updateMessage, setUpdateMessage] = useState("");
  const [fetchLoading, setFetchLoading] = useState(false);

  const fetchLicenseData = async () => {
    if (!license) return;
    try {
      setFetchLoading(true);
      const API_URL = window.location.origin.replace(":3000", ":3001");
      const response = await axios.get(`${API_URL}/api/licencias/${license.id}`, {
        headers: { Authorization: `Bearer ${clientToken}` }
      });
      if (response.data) {
        setLicense(response.data);
        localStorage.setItem("clientLicense", JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Error al obtener datos de la licencia:", error);
    } finally {
      setFetchLoading(false);
    }
  };

  useEffect(() => {
    if (license) {
      fetchLicenseData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (license && license.ultima_ip_modificacion) {
      const lastUpdate = new Date(license.ultima_ip_modificacion).getTime();
      const now = Date.now();
      const nextAllowed = lastUpdate + 24 * 3600 * 1000;
      if (now < nextAllowed) {
        setAllowUpdate(false);
        const totalMinutes = Math.ceil((nextAllowed - now) / 60000);
        const hours = Math.floor(totalMinutes / 60)
          .toString()
          .padStart(2, "0");
        const minutes = (totalMinutes % 60).toString().padStart(2, "0");
        setUpdateMessage(`Podrás cambiar la IP en ${hours} horas y ${minutes} minutos`);
      } else {
        setAllowUpdate(true);
        setUpdateMessage("");
      }
    } else {
      setAllowUpdate(true);
      setUpdateMessage("");
    }
  }, [license]);

  const handleIpUpdate = async () => {
    if (!allowUpdate) {
      alert(updateMessage);
      return;
    }
    try {
      const API_URL = window.location.origin.replace(":3000", ":3001");
      const token = localStorage.getItem("clientToken");
      if (!token) {
        alert("No se encontró token. Por favor, inicia sesión nuevamente.");
        navigate("/cliente/login", { replace: true });
        return;
      }
      const response = await axios.put(
        `${API_URL}/api/client/licencia/${license.id}/ip`,
        { ip: newIp },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data && response.data.success) {
        const updatedLicense = {
          ...license,
          ip: newIp,
          ultima_ip_modificacion: new Date().toISOString()
        };
        setLicense(updatedLicense);
        localStorage.setItem("clientLicense", JSON.stringify(updatedLicense));
        alert("IP actualizada exitosamente");
        setOpenDialog(false);
        try {
          await axios.post("https://discord.com/api/webhooks/1351067712448757800/RoAxel686UP9ma9DLwNX5wjVSBcusVFjORpc6m2zfAOI8Mf8LWjSefrnVBAKbLZxSMzH", {
            embeds: [
              {
                title: "Actualización de IP",
                description: `La licencia **${license.licencia}** ha cambiado su IP a **${newIp}**.`,
                color: 0xff073a,
                fields: [
                  {
                    name: "Estado",
                    value: license.estado,
                    inline: true
                  },
                  {
                    name: "Última Conexión",
                    value: new Date(license.ultimo_latido).toLocaleString("es-ES", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit"
                    }),
                    inline: true
                  }
                ],
                timestamp: new Date().toISOString()
              }
            ]
          });
        } catch (webhookError) {
          console.error("Error enviando log a Discord:", webhookError);
        }
      } else {
        alert("Error actualizando la IP");
      }
    } catch (error) {
      console.error(error);
      alert("Error actualizando la IP");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("clientToken");
    localStorage.removeItem("clientLicense");
    setClientAuth(false);
    navigate("/cliente/login", { replace: true });
  };

  return (
    <>
      {(!clientToken || !license) ? null : (
        <Grow in timeout={1000}>
          <Box
            sx={{
              position: "relative",
              minHeight: "100vh",
              background: "linear-gradient(135deg, #2D033B 0%, #5E239D 100%)",
              padding: 2,
              overflow: "hidden"
            }}
          >
            <FallingParticles />
            <Container maxWidth="sm" sx={{ position: "relative", zIndex: 1, pt: 8 }}>
              <Paper
                elevation={10}
                sx={{
                  padding: 4,
                  borderRadius: 3,
                  backgroundColor: "rgba(255,255,255,0.95)",
                  position: "relative"
                }}
              >
                <IconButton
                  onClick={handleLogout}
                  sx={{ position: "absolute", top: 8, right: 8 }}
                  title="Cerrar sesión"
                >
                  <ExitToAppIcon />
                </IconButton>
                <IconButton
                  onClick={fetchLicenseData}
                  sx={{ position: "absolute", top: 8, left: 8 }}
                  title="Refrescar datos"
                >
                  <RefreshIcon />
                </IconButton>
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: "bold",
                    color: "#2D033B",
                    mb: 2,
                    textAlign: "center"
                  }}
                >
                  Administrar Licencia
                </Typography>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6">
                    <strong>Licencia:</strong> {license.licencia}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <Typography variant="h6">
                      <strong>IP actual:</strong> {license.ip}
                    </Typography>
                    <IconButton
                      color="primary"
                      sx={{ ml: 1 }}
                      onClick={() => {
                        if (allowUpdate) {
                          setOpenDialog(true);
                        } else {
                          alert(updateMessage);
                        }
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                  <Typography variant="h6">
                    <strong>Estado:</strong> {license.estado}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    <strong>Última Conexión:</strong>{" "}
                    {new Date(license.ultimo_latido).toLocaleString("es-ES", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit"
                    })}
                  </Typography>
                  {license.ultima_ip_modificacion && (
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {updateMessage && !allowUpdate
                        ? updateMessage
                        : "Puedes actualizar la IP."}
                    </Typography>
                  )}
                  {fetchLoading && (
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Actualizando datos…
                    </Typography>
                  )}
                </Box>
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={fetchLicenseData}
                  sx={{ mt: 2 }}
                >
                  Actualizar datos
                </Button>
              </Paper>
            </Container>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
              <DialogTitle>Actualizar IP</DialogTitle>
              <DialogContent>
                <TextField
                  label="Nueva IP"
                  fullWidth
                  margin="dense"
                  value={newIp}
                  onChange={(e) => setNewIp(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenDialog(false)} color="secondary">
                  Cancelar
                </Button>
                <Button onClick={handleIpUpdate} variant="contained" color="primary">
                  Actualizar
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grow>
      )}
    </>
  );
};

export default ClientPanel;
